import { walk } from "./objects";

export function range(size: number): number[];
export function range(from: number, to?: number, step?: number): number[];

/**
 * Generates a list of values between two numbers
 *
 * @param from starting index (inclusive)
 * @param to ending index (exclusive)
 * @param step defaults to 1
 */
export function range(from: number, to?: number, step: number = 1): number[] {
  const [start, end] = undefined !== to ? [from, to / step] : [0, from / step];
  return Array.from(Array(end - start), (_, i) => i * step + start);
}

/**
 * Check if two arrays contain all the same elements (regardless of order)
 *
 * @param a
 * @param b
 */
export function arrequal<T>(a: T[], b: T[]): boolean {
  if (!a || !b || a.length !== b.length) return false;
  return !a.some((o, idx) => !b[idx] || b[idx] !== o);
}

export function arrdupes<T>(a: T[]): boolean {
  return new Set(a).size !== a.length;
}

export function arrdiff<T>(a: T[], b: T[]) {
  const diff = (a: T[], b: T[]) => a.filter((item) => !b.includes(item));
  return [...diff(a, b), ...diff(b, a)];
}

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export function byKey<T = unknown>(key: string) {
  return (a: T, b: T) => {
    if (walk(key, a) === walk(key, b)) return 0;
    return (
      typeof walk(key, a) === "string"
        ? walk(key, a).toLowerCase() < walk(key, b).toLowerCase()
        : walk(key, a) < walk(key, b)
    )
      ? -1
      : 1;
  };
}

export function transformMap<T, N>(thing: null, transform?: (orig: T) => N): null;
export function transformMap<T, N>(thing: T, transform?: (orig: T) => N): N;
export function transformMap<T, N>(thing: T[], transform?: (orig: T) => N): N[];
export function transformMap<T, N>(thing: T | T[] | null, transform?: (orig: T) => N) {
  if (!thing) return null;
  if (!transform) return thing;

  if (Array.isArray(thing)) {
    return thing.map(transform);
  } else {
    return transform(thing);
  }
}
