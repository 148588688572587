import { Override } from "../types/index";
import { Calendar, calendarToDto, dtoToCalendar, SyncCalendarType } from "./Calendars";
import { CalendarSyncPolicy as CalendarSyncPolicyDto } from "./client";
import { EventColor } from "./EventMetaTypes";
import { TransformDomain } from "./types";

export enum SyncTransparency {
  Default = "DEFAULT",
  SemiPrivate = "SEMI_PRIVATE",
  Private = "PRIVATE",
  Public = "PUBLIC",
  SemiPrivateAlt = "SEMI_PRIVATE_ALT",
}

export type CalendarSyncPolicy = Override<
  CalendarSyncPolicyDto,
  {
    sourceCalendarId: number;
    targetCalendarId: number;
    sourceCalendar?: Calendar;
    targetCalendar?: Calendar;
    color?: EventColor;
    type?: SyncCalendarType;
    transparency?: SyncTransparency;
  }
>;

export function dtoToCalendarSyncPolicy(dto: CalendarSyncPolicyDto): CalendarSyncPolicy {
  return {
    ...dto,
    sourceCalendar: !!dto.sourceCalendar ? dtoToCalendar(dto.sourceCalendar) : undefined,
    targetCalendar: !!dto.targetCalendar ? dtoToCalendar(dto.targetCalendar) : undefined,
    color: EventColor.get(dto.color),
  } as CalendarSyncPolicy;
}

export function calendarSyncPolicyToDto(policy: Partial<CalendarSyncPolicy>): Partial<CalendarSyncPolicyDto> {
  return {
    ...policy,
    sourceCalendar: !!policy.sourceCalendar ? calendarToDto(policy.sourceCalendar) : undefined,
    targetCalendar: !!policy.targetCalendar ? calendarToDto(policy.targetCalendar) : undefined,
    color: policy.color?.toJSON() as CalendarSyncPolicyDto["color"],
  } as CalendarSyncPolicyDto;
}

export class CalendarSyncPolicyDomain extends TransformDomain<CalendarSyncPolicy, CalendarSyncPolicyDto> {
  resource = "CalendarSyncPolicy";
  cacheKey = "calendarSyncPolicies";
  pk = ["sourceCalendarId", "targetCalendarId"];

  public deserialize = dtoToCalendarSyncPolicy;
  public serialize = calendarSyncPolicyToDto;

  list = this.deserializeResponse(this.api.calendars.getSyncPolicies);

  patch = this.deserializeResponse((sourceId: number, targetId: number, data: Partial<CalendarSyncPolicy>) =>
    this.api.calendars.patchSyncPolicy(sourceId, targetId, this.serialize(data))
  );

  // FIXME (IW): Insane gymnastics going on in here with no error handling
  create = this.deserializeResponse(
    async (
      syncPolicy: Override<
        Partial<CalendarSyncPolicy>,
        {
          sourceCalendar: Calendar;
          targetCalendar: Calendar;
        }
      >
    ) => {
      const payload = { ...syncPolicy };

      // Create source calendar sync if it doesn't exist
      if (!payload.sourceCalendar.id) {
        const sourceCalendar = await this.client.calendars.createSync({
          calendar: calendarToDto(payload.sourceCalendar),
        });
        if (!!sourceCalendar) payload.sourceCalendar = sourceCalendar;
      }

      // Create target calendar sync if it doesn't exist
      if (!payload.targetCalendar.id) {
        const targetCalendar = await this.client.calendars.createSync({
          calendar: calendarToDto(payload.targetCalendar),
        });
        if (!!targetCalendar) payload.targetCalendar = targetCalendar;
      }

      // Prep the policy payload
      payload.sourceCalendarId = payload.sourceCalendar.id;
      payload.targetCalendarId = payload.targetCalendar.id;

      return await this.api.calendars.createSyncPolicy1({
        calendarSyncPolicy: this.serialize(payload),
      });
    }
  );

  delete = this.deserializeResponse((sourceId: number, targetId: number) => {
    return this.api.calendars.deleteSyncPolicy(sourceId, targetId);
  });

  testSyncPolicy = (policy: CalendarSyncPolicy) => this.api.calendars.testSyncPolicy(this.serialize(policy));
}
