import { add, parse, startOfDay } from "date-fns";
import { DayOfWeek } from "../reclaim-api/Calendars";
import { CalendarSyncPolicy } from "../reclaim-api/CalendarSyncPolicy";
import { Habit } from "../reclaim-api/Habits";
import { TimePolicyType, User } from "../reclaim-api/Users";
import { getDayOfWeekFromDate } from "./dates";

export const getMonogram = (name?: string, email?: string): string | null => {
  if (!name && !email) return null;
  const chunks = !!name
    ? name
        .replace(/[^\w^\s]/gi, "")
        .trim()
        .split(/\s+/)
    : email
        ?.replace(/[^\w^\-^\.]/gi, "")
        .replace(/^[\-\.]/gi, "")
        .split(/[-.]/) || [];
  return chunks.map((chunk) => (chunk[0] || "").toUpperCase()).join("");
};

export const getUserMonogram = (user: User | null): string | null => {
  if (!user || !!user.avatarUrl) return null;
  return getMonogram(user.name, user.email);
};

export const getStartOrEndOfDayForUserPolicy = (user: User, date: Date, policy: TimePolicyType, lookup: "start" | "end"): Date | null => {
  let returnDate: Date | null = null;
  const userPolicy = user.features.timePolicies[policy];

  if (!!userPolicy) {
    const day = getDayOfWeekFromDate(date);
    const hourOfDay = lookup === "end" ? userPolicy.dayHours[day]?.endOfDay : userPolicy.dayHours[day]?.intervals?.[0]?.start;
    
    if (!!hourOfDay) {
      returnDate = parse(hourOfDay, "HH:mm:ss", startOfDay(date));
    }
  }

  return returnDate;
}

export const timeFitsInUserPolicy = (user: User, date: Date, policy: TimePolicyType): boolean => {
  let fitsPolicy = false;
  const userPolicy = user.features.timePolicies[policy];
  const dateStart = startOfDay(date);
  const dateEpoch = date.getTime();

  if (!!userPolicy) {
    const day = getDayOfWeekFromDate(date);
    const intervals = userPolicy.dayHours[day]?.intervals;

    if (!!intervals && !!intervals.length) {
      const fit = intervals.find((i) => {
        const start = parse(i.start, "HH:mm:ss", dateStart);
        const end = parse(i.end, "HH:mm:ss", dateStart);
        return start.getTime() <= dateEpoch && dateEpoch <= end.getTime();
      });

      fitsPolicy = !!fit;
    }
  }
  return fitsPolicy;
};

export const scanForPolicyStartOrEndHour = (
  user: User,
  date: Date,
  policy: TimePolicyType,
  lookup: "start" | "end",
  reverse?: boolean,
): Date | null => {
  let hour: Date | null = null;
  let userPolicy = user.features.timePolicies[policy];

  if (!!userPolicy) {
    let scanDate = startOfDay(date);
    let scanTick = 0;
    let scanDay: DayOfWeek = getDayOfWeekFromDate(scanDate);

    while (!hour && scanTick <= 6) {
      const dayStartOrEnd =
        lookup === "end" ? userPolicy.dayHours[scanDay]?.endOfDay : userPolicy.dayHours[scanDay]?.intervals?.[0]?.start;

      if (!!dayStartOrEnd) {
        let [hours] = dayStartOrEnd.split(":");
        scanDate.setHours(+hours);
        hour = scanDate;
      } else {
        scanDate = startOfDay(add(scanDate, { days: !!reverse ? -1 : 1 }));
        scanDay = getDayOfWeekFromDate(scanDate);
        scanTick++;
      }
    }
  }

  return hour;
};

export const userSyncsMaxedOut = (user?: User | null, policies?: CalendarSyncPolicy[] | null): boolean => {
  const unlimitedSync = user?.entitlements.unlimitedSyncEnabled;
  const enabledPolicies = policies?.filter((i) => !!i.enabled).length;

  return !unlimitedSync && !!enabledPolicies;
}

export const userHabitsMaxedOut = (user?: User | null, habits?: Habit[]): boolean => {
  if (!user) return true;

    const isUnlimitedHabits = user.entitlements.unlimitedHabitsEnabled;
    const maxEnabledHabits = user.edition?.limits.maxDailyHabits || 3;
    const numEnabledHabits = habits?.filter((h) => !!h.enabled).length || 0;

    return !isUnlimitedHabits && numEnabledHabits >= maxEnabledHabits;
}
