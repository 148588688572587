import { Override } from "../types";
import { ApiKey as ApiKeyDto } from "./client";
import { EasyResponseTypeCaster } from "./domainTypeHelpers";
import { TransformDomain } from "./types";

export type ConnectedAccountType = ApiKeyDto;

export type ApiKeyLifetimeMsDto = ApiKeyDto["lifetimeMs"];
export type ApiKeyLifetimeMs = number | "forever";
export type ApiKey = Readonly<
  Override<
    ApiKeyDto,
    {
      lifetimeMs: ApiKeyLifetimeMs;
      token?: string;
    }
  >
>;

// `undefined > 0` and `null > 0` both resolve to `false`.
// Typescript doesn't seem to know this so we have to do
// some typing leg work
export const dtoToLifetimeMs = (dto: ApiKeyLifetimeMsDto): ApiKeyLifetimeMs =>
  ((dto as number) > 0 ? dto : "forever") as ApiKeyLifetimeMs;
export const lifetimeMsToDto = (lifetimeMs: ApiKeyLifetimeMs): ApiKeyLifetimeMsDto =>
  lifetimeMs === "forever" ? -1 : lifetimeMs;

// TODO: right now `description` is required for patching.  Make all of this optional once Igor fixes it
export type PatchableApiKeyFields = Partial<Pick<ApiKey, "lifetimeMs">> & Required<Pick<ApiKey, "description">>;

export const dtoToApiKey = ({ lifetimeMs, token, ...dto }: ApiKeyDto): ApiKey => ({
  ...dto,
  token: token || undefined,
  lifetimeMs: dtoToLifetimeMs(lifetimeMs),
});

export const apiKeyToDto = ({ lifetimeMs, ...apiKey }: ApiKey): ApiKeyDto => ({
  ...apiKey,
  lifetimeMs: lifetimeMsToDto(lifetimeMs),
});

export class ApiManagementDomain extends TransformDomain<ApiKey, ApiKeyDto> {
  resource = "ApiKey";
  cacheKey = "apiKey";
  pk = "id";

  public serialize = apiKeyToDto;
  public deserialize = dtoToApiKey;

  listApiKeys = this.typedManageErrors(
    this.deserializeResponse(this.api.apiManagement.listApiKey1 as EasyResponseTypeCaster<ApiKeyDto[]>)
  );

  getApiKey = this.typedManageErrors(
    this.deserializeResponse(this.api.apiManagement.listApiKey as EasyResponseTypeCaster<ApiKeyDto, [id: string]>)
  );

  createApiKey = this.typedManageErrors(
    this.deserializeResponse((description: string, lifetimeMs?: ApiKeyLifetimeMs) =>
      this.api.apiManagement.createApiKey({
        description,
        lifetimeMs: lifetimeMs && lifetimeMsToDto(lifetimeMs),
      } as ApiKeyDto)
    )
  );

  patchApiKey = this.typedManageErrors(
    this.deserializeResponse((id: string, patch: PatchableApiKeyFields) =>
      this.api.apiManagement.updateApiKey({ ...(patch as ApiKeyDto), id })
    )
  );

  deleteApiKey = this.typedManageErrors(this.api.apiManagement.deleteApiKey);

  reissueApiKey = this.typedManageErrors(this.deserializeResponse(this.api.apiManagement.reissueApiKey));
}
